@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');
//-------------------------
//--- Theme Typography
//-------------------------
$enable-responsive-font-sizes: true;
$font-family-base:            'Inter', sans-serif;;

//custom font-family
$btn-font-family: $font-family-base;
$heading-font-family: $font-family-base;
$paragraph-font-family: $font-family-base;


$multiple-font-family: false;
$font-families: (
  "1": $font-family-base,
  "2": $font-family-base,
  "fontName": $font-family-base
);


$headings-font-weight:        700;
// $headings-line-height:        1.5;

//- Display Sizes

$display1-size:               6rem; // About 96px
$display2-size:               5rem; // About 80px
$display3-size:               3.75rem; // About 60px
$display4-size:               3.125rem; // About 50px


//- Font Sizes 
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25;
$font-size-sm:                13px;

$h1-font-size:                3rem; //About 48px
$h2-font-size:                2.5rem; //About 40px
$h3-font-size:                2.125rem;  //About 34px
$h4-font-size:                1.5rem; //About 24px
$h5-font-size:                1.3125rem; //About 21px
$h6-font-size:                1rem; //About 16px



$text-base:                  1.125rem; //18px


$fsize: (
  1:(
    font-size:8px,//8px
    line-height:1,
  ),
  2:(
    font-size:11px,//11px
    line-height:1
    ),
  3:(
    font-size:13px,//13px
    line-height:2,
    letter-spacing:0.26px
  ), //-0.4
  4:(
    font-size:16px,//16px
    line-height:1.625,
    letter-spacing:-0.08px
  ), // 
  5:(
    font-size:18px,//18px
    line-height:1.66,
    letter-spacing:-0.09px
    ),
  6:(
    font-size:21px,//21px
    line-height:1.62,
    letter-spacing:-0.21px
  ),
  7:(
    font-size:24px,//24px
    line-height:1.5,
    letter-spacing:-0.24px
    ),
  8:(
    font-size:34px,//34px
    line-height:1.76,
    letter-spacing:-0.34px
    ),
  9:(
    font-size:40px,//40px
    line-height:1.375,
    letter-spacing:-0.4px
    ),
  10:(
    font-size:48px,//48px
    line-height:1.25,
    letter-spacing:-0.48px
    ),
  11:(
    font-size:60px,//60px
    line-height:1.2,
    letter-spacing:-0.9px
    ),
  12:(
    font-size:4.375rem,//70px
    line-height:1.2,
    letter-spacing:-4px
    )
);